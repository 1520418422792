/* .app-loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(201, 121, 121, 0.6);
  z-index: 99999999;
}
.app-loader-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #D3CCE3;
  background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);
  background: linear-gradient(to right, #E9E4F0, #D3CCE3);
  padding: 15px;
  border-radius: 15px;
}
.app-loader-message {
  font-size: 24px;
  color: #ffffff;
}
.app-loader-div > div.app-loader {

}

.loader {
  z-index: 9999999;
} */


.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.loader-base {
  display: flex;
  justify-content: center;
  align-self: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}