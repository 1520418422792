@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', */
  font-family: 'Poppins', sans-serif;
    /* 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;*/
} 

/* * {
  font-family: 'Poppins', sans-serif;
} */

.main-content-wrapper {
  min-height: calc(100vh - 300px);
}
/* form invalid test style */
.invalid-feedback {
  display: block;
  text-align: left;
  font-size: 14px;
}

/* date picker styles */
.ne-datepicker-container {
  position: relative;
}
.ne-datepicker-container .react-datepicker-wrapper {
  width: 100%;
}
.ne-datepicker-container .datepicker-icon {
  position: absolute;
  top: 25%;
  right: 3%;
}
.ne-datepicker-container.dark .react-datepicker__input-container input {
  border: 1px solid #676767;
}
/* .app-loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(201, 121, 121, 0.6);
  z-index: 99999999;
}
.app-loader-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #D3CCE3;
  background: -webkit-linear-gradient(to right, #E9E4F0, #D3CCE3);
  background: linear-gradient(to right, #E9E4F0, #D3CCE3);
  padding: 15px;
  border-radius: 15px;
}
.app-loader-message {
  font-size: 24px;
  color: #ffffff;
}
.app-loader-div > div.app-loader {

}

.loader {
  z-index: 9999999;
} */


.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 9999;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}
.loader-base {
  display: flex;
  justify-content: center;
  align-self: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
